import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border-top: 1px solid #dddddd;
`;

export const Content = styled.div`
  max-width: 1380px;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;
  column-gap: 10px;
  font-size: calc(1rem + 1vmin);
  font-family: serif;
  margin: auto;
  padding: 20px;
  line-height: calc(1rem + 1vmin);
  .card {
    flex: 0 1 30%;
    margin-bottom: 20px;
    @media only screen and (min-width: 600px) and (max-width: 989px) {
      flex: 0 1 47%;
    }

    /*** this is not necessary because on that screen-width the Enfold default is 100%  ***/
    @media only screen and (max-width: 599px) {
      flex: 0 1 100%;
    }
  }
`;

export const Downloads = styled.div`
  margin-top: 5px;
  color: #666666;
  font-size: 12px;
`;

export const Span = styled.span`
  margin-top: 5px;
  color: #666666;
  font-size: 12px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: underline;
`;
