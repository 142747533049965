import { createGlobalStyle } from 'styled-components';
import 'normalize.css';

export const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 62.5%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-size: 1.6rem;
  }

  a {
    color: currentColor;
    display: block;
    text-decoration: none;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  h1 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 12px;
  }
  h2 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 12px;
  
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  h4 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  h5 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  h6 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 12px;
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  
  p {
    margin: 0;
    margin-bottom: 12px;
    font-weight: 400;
    &:last-child {
      margin-bottom: 24px;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  input,
  select {
    border: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  svg {
    fill: currentColor;
    height: 100%;
    width: 100%;
  }
`;
