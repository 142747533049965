import { StaticImage } from 'gatsby-plugin-image';
import React, { FunctionComponent } from 'react';
import * as S from './footer.styled';

export const Footer: FunctionComponent = () => {
  return (
    <S.Container>
      <S.Content>
        <div className="card">
          <StaticImage
            src="../../assets/images/pilotwave.png"
            alt="Pilot Wave"
            placeholder="blurred"
            layout="constrained"
            width={200}
            formats={['auto', 'webp', 'avif']}
          />
        </div>
        <div className="card">
          <S.Downloads>
            <p>
              <strong>Downloads (PDF):</strong>
            </p>
            <S.Link href="https://www.google.de" target="_blank">
              Download A
            </S.Link>
            <S.Link href="https://www.google.de" target="_blank">
              Download B
            </S.Link>
          </S.Downloads>
        </div>
        <div className="card">
          <S.Span>
            <p>
              <strong>Pilotwave GmbH</strong>
            </p>
            <p>
              Martin Goetze
              <br />
              Regensdorferstrasse 27
              <br />
              8155 Niederhasli
              <br />
              Schweiz
            </p>
            <p>
              E-Mail: info@pilotwave.ch
              <br />
              Trade Register Entry: CHE-473.267.593
            </p>
          </S.Span>
        </div>
      </S.Content>
    </S.Container>
  );
};
