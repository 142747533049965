import styled from 'styled-components';

export const Container = styled.div`
  background: rgb(45, 170, 225);
  background: linear-gradient(90deg, rgba(45, 170, 225, 1) 0%, rgba(43, 92, 165, 1) 100%);
  color: white;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  max-width: 1380px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  font-size: calc(1rem + 2vmin);
  font-family: serif;
  margin: auto;
  padding: 15px 10px;
`;

export const Span = styled.span`
  margin-top: 5px;
  ${({ theme }) => theme.colors.primary}
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;
