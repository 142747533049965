import React, { FunctionComponent } from 'react';
import { PageProps } from 'gatsby';
import Headroom from 'react-headroom';
import { GlobalStyle } from '../../style/GlobalStyle';
import { SEO } from '../SEO/SEO';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';

export const Layout: FunctionComponent<PageProps> = ({ children, location }) => (
  <>
    <GlobalStyle />
    <SEO location={location} />
    <header>
      <Headroom>
        <Header />
      </Headroom>
    </header>
    <section>{children}</section>
    <footer>
      <Footer />
    </footer>
  </>
);
