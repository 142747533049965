import { StaticImage } from 'gatsby-plugin-image';
import React, { FunctionComponent } from 'react';
import * as S from './header.styled';

export const Header: FunctionComponent = () => {
  return (
    <S.Container>
      <S.Content>
        <StaticImage
          src="../../assets/images/pilotwave-inverted.png"
          alt="Pilot Wave"
          placeholder="blurred"
          layout="fixed"
          width={200}
          formats={['auto', 'webp', 'avif']}
          loading="eager"
        />
      </S.Content>
    </S.Container>
  );
};
